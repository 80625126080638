import React from 'react'
import get from 'lodash/get'

import { LinkButton } from '~/components/Button'
import FestivalInfo from '~/components/FestivalInfo'
import withLandingNamespaces from '~/hoc/withLandingNamespaces'
import { getCitiesList } from '~/utils/helpers'

@withLandingNamespaces()
export default class HeroVoucherContent extends React.Component {
  state = {
    loading: false
  }

  setLoading = () => this.setState({ loading: true })

  render () {
    const { loading } = this.state
    const { festivalData, regions, t, showExtra } = this.props

    return (
      <div className='hero-voucher-content'>
        {/* TODO: revert #covid */}
        {<FestivalInfo
          className='hero-voucher-content__date'
          festival={festivalData}
          withPrice={false}
        />}
        <h1
          className='hero-voucher-content__title'
          dangerouslySetInnerHTML={{ __html: t('hero_section.title_voucher') }}
        />
        <p
          className='hero-voucher-content__info'
          dangerouslySetInnerHTML={{ __html: t('hero_section.info') }}
        />
        {showExtra && <h2
          className='hero-voucher-content__info'
          dangerouslySetInnerHTML={{ __html: t('hero_section.extra') }}
        />}
        <div className='hero-voucher-content__button-wrapper'>
          <LinkButton
            modifiers={['primary', 'big', 'full-width', 'with-glow']}
            route={'voucher-details'}
            params={{ festivalId: get(festivalData, 'id') }}
            label={t('hero_section.voucher_button')}
            onClick={this.setLoading}
            loading={loading}
            disabled={loading}
          />
        </div>
        <div className='hero-voucher-content__location'>
          {getCitiesList(regions)}
        </div>
      </div>
    )
  }
}
